<template>
  <div>
    <button @click="authenticate">Scan Fingerprint</button>
    <p v-if="status">{{ status }}</p>
  </div>
</template>

<script>
export default {
    data() {
        return {
            status:true
        }
    },
    methods: {
        authenticate() {
  // Load FingerprintJS library dynamically
  const script = document.createElement('script');
  script.src = 'https://openfpcdn.io/fingerprintjs/v4';
  script.async = true;
  script.onload = () => {
    // Once the script is loaded, initialize FingerprintJS
    FingerprintJS.load().then(fp => {
      // Get the visitor identifier
      fp.get().then(result => {
        // This is the visitor identifier:
        const visitorId = result.visitorId;
        console.log(visitorId);
        // You can use the visitorId as needed, such as sending it to a server for tracking or authentication purposes.
      }).catch(error => {
        console.error('FingerprintJS Error:', error);
      });
    }).catch(error => {
      console.error('Failed to load FingerprintJS:', error);
    });
  };
  document.body.appendChild(script);
}
    }

}
</script>
